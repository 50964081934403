'use strict';

angular.module('enervexSalesappApp').controller('FittingCtrl', function($scope, $q, Util, Fitting, $stateParams, Flash, $state, _, Image, FileUploader,VentMaterial, Application, ApplicationSubtype, ProductSubtype) {
	$scope.errors = {};
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		if ($stateParams.copyId) {
			Fitting.get({
				id: $stateParams.copyId
			}).$promise.then(function(fitting) {
				$scope.fitting = _.omit(fitting, "_id","__version");
				fetchLookups()
			})
		} else {
			$scope.fitting = {
				applications:[],
				ventMaterials:[],
				name: '',
				secno: 1,
				code:'',
				sortOrder: 0,
				angles:{
					centerPointA:0,
					centerPointB:0,
					centerPointC:0,
					centerPointD:0
				}
			}
			fetchLookups()
		}
		$scope.itemCodeDisabled = false
	} else {
		Fitting.get({
			id: $stateParams.id
		}).$promise.then(function(fitting) {
			$scope.fitting = fitting;
			if (!$scope.fitting.angles){
				$scope.fitting.angles = {}
			}
			fetchLookups()
		})
	}
	$scope.changed = function() {
		$scope.ctx.formChanged = true
	}
	$scope.ctx = {
		formChanged: false,
		showCenterpointLateral: false,
		showCenterpointSize: false,
		showCenterpointOutlet: false
	}
	$scope.fittingConfigTypes = [{
		name: 'Three Way',
		_id: 'threeWay',
	}, {
		name: 'Two Way Horizontal',
		_id: 'twoWayHorizontal',
	}, {
		name: 'Pure Vertical Supported 2way',
		_id: 'twoWayPureVertical',
	}, {
		name: 'Pure Vertical Not Supported 2way',
		_id: 'twoWayPureVerticalAllowed',
	}, {
		name: 'StartingPoint First Turn',
		_id: 'startingPoint',
	}]
	$scope.cpOutlets = [{
		name:'A',
		_id:'A'
	},{
		name:'B',
		_id:'B'
	},{
		name:'C',
		_id:'C'
	},{
		name:'D',
		_id:'D'
	}];
	$scope.createFittingConfig = function() {
		var fittingConfig = {
			_id: Util.objectId(),
			applications: [],
			accessories:[],
			type: 'twoWayHorizontal'
		}
		hydrateApplications(fittingConfig)
		hydrateAccessories(fittingConfig)
		$scope.fitting.fittingConfigs.push(fittingConfig)
	}
	$scope.removeFittingConfig = function(fittingConfig) {
		$scope.fitting.fittingConfigs = _.filter($scope.fitting.fittingConfigs, function(o){
			return o != fittingConfig
		})
	}
	function fetchLookups() {
		if (!$scope.fitting.fittingConfigs) {
			$scope.fitting.fittingConfigs = []
		}
		Application.query({
			limit:3000
		}).$promise.then(function(applications){
			$scope.allApplications = applications
			$scope.applications = _.map(applications, function(application){
				var existing = _.find($scope.fitting.applications, function(o){
					return o.application._id == application._id
				})
				var wrapper = null;
				if (existing){
					wrapper = existing;
					existing.selected = true
				} else {
					wrapper= {
						_id:Util.objectId(),
						selected:false,
						application: application,
						image: ''
					}
				}
				wrapper.uploader = new FileUploader({});
				wrapper.uploader.onAfterAddingFile = function() {
					$scope.ctx.formChanged = true;
				}
				return wrapper;
			});
			_.each($scope.fitting.fittingConfigs, function(fittingConfig){
				hydrateApplications(fittingConfig)
			})
		})
		$scope.centerpoints = ['A', 'B', 'C', 'D'];
		$scope.centerpointSizes = ['large', 'small'];
		
		$scope.uploader = new FileUploader({});
		$scope.uploader.onAfterAddingFile = function() {
			$scope.ctx.formChanged = true;
		}
		VentMaterial.query().$promise.then(function(ventMaterials){
			$scope.ventMaterials = _.map(ventMaterials, function(ventMaterial){
				var existing = _.find($scope.fitting.ventMaterials, function(v){
					return v._id == ventMaterial._id
				})
				if (existing){
					ventMaterial.selected = true
				}
				return ventMaterial
			})
		})
		$q.all([
			ApplicationSubtype.query().$promise,
			ProductSubtype.query().$promise,
		]).then(function(result){
			var applicationSubtypes = result[0]
			$scope.allApplicatoinSubtypes = result[0]
			$scope.productSubtypes = Util.sortUncased(result[1], "name")
			$scope.accessories = Util.getSubtypes("Accessory", $scope.productSubtypes)
			$scope.productSubtypesCovers = _.filter($scope.productSubtypes, function(productSubtype){
				return productSubtype.inletCover
			})
			_.each($scope.fitting.fittingConfigs, function(fittingConfig){
				hydrateAccessories(fittingConfig)
			})
		})
	}
	function hydrateApplications(fittingConfig) {
		fittingConfig.applications = _.map($scope.allApplications, function(_application){
			var application = _.clone(_application)
			var existing = _.find(fittingConfig.applications, function(o2){
				return _.isString(o2) ? o2 == application._id : o2._id == application._id
			})
			if (existing) {
				application.selected = true
			}
			application.tempId = fittingConfig._id + "-"+ application._id
			return application
		})
	}
	function hydrateAccessories(fittingConfig) {
		fittingConfig.accessories = _.map($scope.accessories, function(_productSubtype){
			var productSubtype = _.clone(_productSubtype)
			var existing = _.find(fittingConfig.accessories, function(p){
				return p == productSubtype._id
			})
			productSubtype.selected = (existing) ? true : false
			return productSubtype
		})
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			uploadAssets().then(function(result){
				return uploadAsset()
			}).then(function(asset){
				var payload = _.clone($scope.fitting);
				if (asset) {
					payload.asset = asset
				} else {
					delete payload.asset
				}
				payload = Util.scrubPayload(payload)
				payload.applications = _.compact(_.map($scope.applications, function(application){
					if (application.selected) {
						var result = _.omit(application, "uploader", "selected")
						result.application = result.application._id
						if (result.asset) {
							result.asset = result.asset._id
						}
						return result
					}
				}))
				payload.fittingConfigs = _.map(payload.fittingConfigs, function(_fittingConfig){
					var fittingConfig = _.clone(_fittingConfig)
					fittingConfig.accessories = _.compact(_.map(fittingConfig.accessories, function(o){
						return o.selected ? o._id : null
					}))
					fittingConfig.applications = _.compact(_.map(fittingConfig.applications, function(o){
						return o.selected ? o._id : null
					}))
					return fittingConfig
				})
				payload.ventMaterials = _.where($scope.ventMaterials, {selected:true});
				if (!payload._id) {
					Fitting.save(payload).$promise.then(function(result) {
						
						$scope.success = true;
						// $scope.fanPhase = result;
						Flash.create('success', '<strong>Success!</strong> Successfully created fitting.', 0, {}, true);
						$state.go("fitting", {
							id: result._id
						}, {
							inherit: false,
							reload: true
						})
					}).catch(function(err) {
						
						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				} else {
					Fitting.update({
						id: payload._id
					}, payload).$promise.then(function(result) {
						
						$scope.success = true;
						// $scope.fanPhase = fanPhase;
						Flash.create('success', '<strong>Success!</strong> Successfully updated fitting.', 0, {}, true);
						$scope.fitting = result;
						fetchLookups()
					}).catch(function(err) {
						
						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				}
			})
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
	function uploadAssets(){
		var apps = _.where($scope.applications, {selected:true})
		var promises = _.map(apps,function(application){
			if (application.uploader.queue.length >0){
				var fd = new FormData();
				var data = application.uploader.queue[0];
				fd.append('asset', data._file);
				application.uploader.clearQueue()
				return Image.save({},fd).$promise.then(function(image){
					application.asset = image
					return $q.resolve(application)
				});
			} else {
				return $q.resolve(application)
			}
		});
		return $q.all(promises)
	}
	function uploadAsset(){
		if ($scope.uploader.queue.length >0){
			var fd = new FormData();
			var data = $scope.uploader.queue[0];
			fd.append('asset', data._file);
			$scope.uploader.clearQueue()
			return Image.save({},fd).$promise.then(function(asset){
				return $q.resolve(asset)
			});
		} else {
			return $q.resolve(null)
		}
	}
});
